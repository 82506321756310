.loader {
  position: relative;
  margin: 30px;
  left: 45%;
  top: 50%;
  /* padding: 50%; */
  border: 16px solid white;
  border-radius: 50%;
  border-top: 16px solid #407bff;
  border-bottom: 16px solid #407bff;
  width: 120px;
  height: 120px;
  /* margin: 50%; */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
