body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
  display: flex;
  justify-content: space-around;
}
.row.header {
  background-color: rgb(248, 249, 251);
  font-weight: bold;
  color: rgb(59, 72, 132);
  padding: 10px 0;
}
.text-center {
  text-align: center;
}
.no-outline {
  outline: none;
}
.no-border {
  border: none !important;
}

.color-theme {
  color: rgb(64, 123, 255);
}
.custom-tooltip,
#chartjs-tooltip {
  padding: 5px;
  background-color: #fff;
  font-size: 1.4rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 20px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 20px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 20px -2px rgba(0, 0, 0, 0.75);
}
.custom-tooltip:after,
#chartjs-tooltip:after {
  content: '';
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  bottom: -10px;
  position: absolute;
  left: calc(50% - 5px);
}
